import { ReactComponent as CopyIcon } from "assets/icon/copy.svg";
import { ReactComponent as EditIcon } from "assets/icon/edit.svg";
import { ReactComponent as FamilyInsuranceIcon } from "assets/icon/family_insurance.svg";
import { ReactComponent as RedTrashIcon } from "assets/icon/red_trash.svg";
import { ReactComponent as UserIcon } from "assets/icon/user.svg";
import {
  Alert,
  Box,
  Button,
  Divider,
  Form,
  Modal,
  Notification,
  Table,
  Typography,
} from "components";
import { MaterialIcon } from "components/common/MaterialIcon";
import { EnumInsuranceFor } from "constants/enums/insurance-for";
import { EnumMasterInsuranceState } from "constants/enums/master-insurance-state";
import { EnumMasterInsuranceSubState } from "constants/enums/master-insurance-sub-state";
import { PackagePricingType } from "constants/enums/package-pricing-type";
import {
  compose,
  withFormik,
  withHooks,
  withStores,
  withTranslation,
} from "enhancers";
import withPreventLeaveDirtyForm from "enhancers/withPreventLeaveDirtyForm";
import { isEmpty, isEqual, orderBy } from "lodash";
import insuranceStore from "stores/insuranceStore";
import styled from "styled-components";
import { AppColor } from "theme/app-color";
import { gql, paths, publishedAlert, toCurrency } from "utils/helper";
import { handleNavigateInsuranceStep } from "../insuranceRoutes";

const mapIcon = {
  employee: <UserIcon />,
  family: <FamilyInsuranceIcon />,
};

const AddButton = styled(Button)`
  min-width: 151px;
`;

const InsurancePackagesIndexPage = (props: any) => (
  <Box width="100%">
    <Form>
      <Typography variant="h4" mb={4}>
        {props.t(".title")}
      </Typography>
      <Alert severity="info" mt={6} mb={8}>
        <Typography variant="body1" color={AppColor["Text/Primary"]}>
          {props.t(".infoText")}
        </Typography>
      </Alert>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        mb={4}
      >
        <Typography variant="h4">{props.t(".tableHeader")}</Typography>
        {props.isEditing && (
          <AddButton onClick={props.goToCreatePackagePage} variant="outlined">
            <MaterialIcon name="Add" mr={4} />
            {props.t(".add")}
          </AddButton>
        )}
      </Box>

      <Box display="flex" alignItems="center" mt={"24px"} mb="16px">
        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          width="24px"
          height="24px"
        >
          <UserIcon />
        </Box>
        <Typography variant="body2" ml="8px">
          {props.t(`.packageFor.employee`)}
        </Typography>
      </Box>
      <Box padding="0px 0px 0px 32px">
        <Table
          columns={props.columns}
          rows={props.tableData}
          density="compact"
          autoHeight
          disableSelectionOnClick
          hideFooterPagination
          hideFooterRowCount
          hideFooter
          noRowText="client.components.Table.noInsuranceData"
          rowsPerPageOptions={[5, 10]}
          style={{ minHeight: "40px" }}
        />
        <Divider />
      </Box>
      {props.isEditing && (
        <Button type="submit" mt="40px">
          {props.hasSubmissionAction
            ? props.t(".saveAndNext")
            : props.t(".save")}
        </Button>
      )}
    </Form>
  </Box>
);

export const API = {
  FETCH_INSURANCE_TYPES: gql`
    query FETCH_INSURANCE_TYPES {
      insuranceTypes {
        id
        symbol
        nameTh
        nameEn
      }
    }
  `,
  FETCH_INSURANCE_DETAIL: gql`
    query FETCH_INSURANCE_DETAIL($id: String!) {
      masterInsuranceDetail(id: $id) {
        id
        year
        state
        subState
        companyName
        protectionStartDate
        protectionEndDate
        insurances {
          id
          nameTh
          nameEn
          premium
          remarkTh
          remarkEn
          description
          insuranceTypeId
          isFamily
          coverageLevel
        }
        insurancePackages {
          id
          nameTh
          nameEn
          remarkTh
          remarkEn
          packagePricingType
          createdAt
          insurancePackagesInsurancePlans {
            id
            insurancePlanId
            packageId
          }
          isFamily
          coverageLevel
          description
          premium
        }
      }
    }
  `,
  UPDATE_INSURANCE_PACKAGE: gql`
    mutation UPDATE_INSURANCE_PACKAGE(
      $id: String!
      $insurancePackages: [JSON!]
    ) {
      updateInsurancePackage(
        input: { id: $id, insurancePackages: $insurancePackages }
      ) {
        id
        subState
        insurancePackages {
          id
          nameTh
          nameEn
          remarkTh
          remarkEn
          packagePricingType
          createdAt
          insurancePackagesInsurancePlans {
            id
            insurancePlanId
            packageId
          }
          isFamily
          coverageLevel
          description
          premium
        }
      }
    }
  `,
  CHECK_HAS_BEEN_USED: gql`
    mutation CHECK_HAS_BEEN_USED($id: String!) {
      checkInsurancePackage(id: $id) {
        hasBeenGroup
      }
    }
  `,
};

const enhancer = compose(
  withStores((stores: any) => ({
    packages: stores.insuranceStore.packages,
    initialPackages: stores.insuranceStore.initialPackages,
  })),
  withFormik({
    mapPropsToValues: () => ({
      isPackageChange: false,
    }),
  }),
  withPreventLeaveDirtyForm({
    onOk: () => insuranceStore.clearData(),
  }),
  withTranslation({ prefix: "pages.main.insurance.InsurancePackage" }),
  withHooks((props: any, hooks: any) => {
    const {
      useMemo,
      useCallback,
      useMutation,
      useParams,
      useQuery,
      useEffect,
      useHandleSubmit,
      useDataTranslation,
      useState,
    } = hooks;
    const {
      packages,
      initialPackages,
      t,
      initialValues,
      setFieldValue,
      disablePreventLeaveDirtyForm,
      enablePreventLeaveDirtyForm,
    } = props;
    const { id: masterInsuranceId } = useParams();

    const { data: insuranceTypeRes } = useQuery(API.FETCH_INSURANCE_TYPES);

    const [year, setYear] = useState("");
    const { data: detail, refetch, loading: detailLoading } = useQuery(
      API.FETCH_INSURANCE_DETAIL,
      {
        variables: { id: masterInsuranceId },
        onCompleted: (data: any) => {
          const response = data.masterInsuranceDetail;
          setYear(response.year);
          if (isEmpty(packages) && insuranceTypeRes.insuranceTypes) {
            insuranceStore.initialInsurancePackages(
              orderBy(response.insurancePackages, "createdAt", "asc"),
              response.insurances,
              insuranceTypeRes.insuranceTypes
            );
          }
        },
        fetchPolicy: "network-only",
      }
    );
    const [checkHasBeenUsed] = useMutation(API.CHECK_HAS_BEEN_USED);

    const hasSubmissionAction = useMemo(() => {
      return (
        detail?.masterInsuranceDetail.subState ===
        EnumMasterInsuranceSubState.insurance_package_creating
      );
    }, [detail]);

    const [updateInsurancePackage] = useMutation(API.UPDATE_INSURANCE_PACKAGE, {
      onCompleted: async (data: any) => {
        insuranceStore.clearData();
        const { id, subState, insurancePackages } = data.updateInsurancePackage;
        Notification.success(t(".saveSuccess"));
        insuranceStore.initialInsurancePackages(
          orderBy(insurancePackages, "createdAt", "asc"),
          detail?.masterInsuranceDetail.insurances,
          insuranceTypeRes.insuranceTypes
        );

        if (hasSubmissionAction) {
          handleNavigateInsuranceStep(id, subState);
        }
      },
      skipSetError: true,
      onError: () => {
        publishedAlert(disablePreventLeaveDirtyForm);
      },
    });

    const checkIsPackageChange = useCallback(
      (initial: any, changed: any) => {
        const prev = JSON.stringify(initial);
        const current = JSON.stringify(changed);

        if (isEqual(prev, current)) {
          setFieldValue("isPackageChange", false);
          // disablePreventLeaveDirtyForm();
        } else {
          setFieldValue("isPackageChange", true);
          enablePreventLeaveDirtyForm();
        }
      },
      [setFieldValue, enablePreventLeaveDirtyForm]
    );

    const deletePackage = useCallback(
      async (params: any) => {
        const { id, name } = params.row;

        Modal.open({
          title: t(".deleteModalTitle"),
          children: (
            <div style={{ display: "flex", flexDirection: "row" }}>
              <Typography variant="body1" color={AppColor["Text/Secondary"]}>
                {t(".deleteModalInfo1")} &nbsp;
              </Typography>
              <Typography variant="body2" color={AppColor["Text/Secondary"]}>
                {name} &nbsp;
              </Typography>
              <Typography variant="body1" color={AppColor["Text/Secondary"]}>
                {t(".deleteModalInfo2")}
              </Typography>
            </div>
          ),
          cancelButtonLabel: t(".close"),
          okButtonLabel: t(".confirmDelete"),
          okButtonVariant: "outlined",
          onOk: async ({ close }: any) => {
            const deletedPackage = packages[id];
            const { data } = await checkHasBeenUsed({
              variables: { id: deletedPackage.id },
            });

            const hasBeenGroup = data?.checkInsurancePackage?.hasBeenGroup;
            if (hasBeenGroup) {
              Notification.error(t(".cannotDeleteWhenHasBeenGroup", { year }));
            } else {
              insuranceStore.deleteInsurancePackageTemp(id);
            }
            close();
          },
        });
      },
      [checkHasBeenUsed, packages, t, year]
    );

    const editInPackage = useCallback(
      async (params: any) => {
        const { id } = params.row;
        disablePreventLeaveDirtyForm();
        paths.insurancePackageEditPath(masterInsuranceId, id).push();
      },
      [masterInsuranceId, disablePreventLeaveDirtyForm]
    );

    const goToCreatePackagePage = useCallback(async () => {
      disablePreventLeaveDirtyForm();
      paths.insurancePackageNewPath(masterInsuranceId).push();
    }, [masterInsuranceId, disablePreventLeaveDirtyForm]);

    const isEditing = useMemo(() => {
      return !(
        detail?.masterInsuranceDetail.state ===
        EnumMasterInsuranceState.published
      );
    }, [detail]);

    const handleDuplicate = useCallback(
      async (insuranceId: any) => {
        await disablePreventLeaveDirtyForm();
        paths
          .insurancePackageDuplicatePath(masterInsuranceId, insuranceId)
          .push();
      },
      [masterInsuranceId, disablePreventLeaveDirtyForm]
    );

    const handleClickDuplicatePackage = useCallback(
      (props: any) => {
        const name = props.row.name;
        Modal.open({
          title: t(".duplicateModalTitle"),
          children: (
            <>
              <div style={{ display: "flex", flexDirection: "row" }}>
                <Typography variant="body1" color={AppColor["Text/Dark Grey"]}>
                  {t(".duplicateModalContent")} &nbsp;
                </Typography>
                <Typography variant="body1" color={AppColor["Text/Dark Grey"]}>
                  {`“${name}”`} &nbsp;
                </Typography>
              </div>
            </>
          ),
          cancelButtonLabel: t(".close"),
          okButtonLabel: t(".duplicateModalTitle"),
          okButtonVariant: "outlined",
          onOk: async ({ close }: any) => {
            await handleDuplicate(props.id);
            close();
          },
        });
      },
      [t, handleDuplicate]
    );

    const tableDataMapped = useMemo(() => {
      return packages.map((item: any, index: any) => {
        return {
          ...item,
          id: index,
          tablePremium: item.premium
            ? toCurrency(item.premium, {
                maximumFractionDigits: 0,
                minimumFractionDigits: 0,
              })
            : t(`.pricingType.${item.packagePricingType}`),
          actions: isEditing
            ? [
                // {
                //   Icon: CopyIcon,
                //   onClick: handleClickDuplicatePackage,
                // },
                {
                  Icon: EditIcon,
                  onClick: editInPackage,
                },
                {
                  Icon: RedTrashIcon,
                  onClick: deletePackage,
                },
              ]
            : [],
        };
      });
    }, [
      packages,
      t,
      isEditing,
      handleClickDuplicatePackage,
      editInPackage,
      deletePackage,
    ]);

    const tableData = useDataTranslation(tableDataMapped);

    const columns = useMemo(
      () => [
        {
          width: 200,
          field: "name",
          headerName: t(".name"),
        },
        {
          width: 100,
          field: "tablePremium",
          headerName: t(".packagePricingType"),
          type: "textEnd",
        },
        // {
        //   width: 60,
        //   field: "coverageLevel",
        //   headerName: t(".level"),
        //   type: "textEnd",
        // },
        {
          width: 230,
          field: "remark",
          headerName: t(".remark"),
        },
        {
          width: 130,
          field: "actions",
          headerName: " ",
          filterable: false,
          sortable: false,
          type: "actions",
        },
      ],
      [t]
    );

    useHandleSubmit(
      async (values: any) => {
        const clearedEmptyPackages = packages.map((value: any) => ({
          ...value,
          premium: null,
          // value.packagePricingType === PackagePricingType.SET_OWN_PRICE
          //   ? value.premium
          //   : null,
          insurances: value.insurances.filter((value: any) => !isEmpty(value)),
        }));

        await updateInsurancePackage({
          variables: {
            id: masterInsuranceId,
            insurancePackages: [...clearedEmptyPackages],
          },
        });
      },
      [masterInsuranceId, packages, updateInsurancePackage]
    );

    useEffect(() => {
      if (!isEmpty(initialValues)) {
        checkIsPackageChange(initialPackages, packages);
      }
    }, [initialValues, checkIsPackageChange, initialPackages, packages]);

    return {
      tableData,
      columns,
      isEditing,
      goToCreatePackagePage,
      hasSubmissionAction,
    };
  })
);

export default enhancer(InsurancePackagesIndexPage);
