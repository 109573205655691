import { findKey } from "lodash";
import { isClient } from "common/helper";
import { cleanEnv, str } from "envalid";

export const envConfig = cleanEnv(process.env, {
  REACT_APP_ENV: str(),
  REACT_APP_VERSION: str({ default: "0.0.0.0" }),
  REACT_APP_DEFAULT_LANG: str({ default: "th" }),
  NODE_ENV: str({ default: "development" }),
});

const config = {
  production: {
    HOST: "https://mybenefit-backoffice-flexben.scg.com",
    API_ENDPOINT: "https://mybenefit-backoffice-flexben.scg.com/graphql",
    API: "https://mybenefit-api-flexben.scg.com",
    BACKOFFICE_ENDPOINT: "https://mybenefit-backoffice-flexben.scg.com",
    APP_ENDPOINT: "https://mybenefit-app-flexben.scg.com",
    MARKETPLACE_ENDPOINT: "https://marketplace-api-flexben.scg.com",
  },
  uat: {
    HOST: "https://mybenefit-backoffice-flexben-uat.scg.com",
    API_ENDPOINT: "https://mybenefit-backoffice-flexben-uat.scg.com/graphql",
    API: "https://mybenefit-api-flexben-uat.scg.com",
    BACKOFFICE_ENDPOINT: "https://mybenefit-backoffice-flexben-uat.scg.com",
    APP_ENDPOINT: "https://mybenefit-app-flexben-uat.scg.com",
    MARKETPLACE_ENDPOINT: "https://marketplace-api-flexben-uat.scg.com",
  },
  staging: {
    HOST: "https://dev-scg2-flex-backoffice.moveplus.dynu.net",
    API_ENDPOINT: "https://dev-scg2-flex-backoffice.moveplus.dynu.net/graphql",
    API: "https://dev-scg2-flex-api.moveplus.dynu.net",
    BACKOFFICE_ENDPOINT: "https://dev-scg2-flex-backoffice.moveplus.dynu.net",
    APP_ENDPOINT: "https://dev-scg2-flex-app.moveplus.dynu.net",
    MARKETPLACE_ENDPOINT: "https://marketplace-api-flexben-uat.scg.com",
  },
  staging2: {
    HOST: "http://localhost:9902",
    API_ENDPOINT: "http://localhost:9901/graphql",
    API: "http://localhost:9901",
    BACKOFFICE_ENDPOINT: "http://localhost:9902",
    APP_ENDPOINT: "http://localhost:9903",
    MARKETPLACE_ENDPOINT: "https://marketplace-api-flexben-uat.scg.com",
  },
  staging3: {
    HOST: "https://dev-scg-my-benefit-backoffice.moveplus.dynu.net",
    API_ENDPOINT:
      "https://dev-scg-my-benefit-backoffice.moveplus.dynu.net/graphql",
    API: "https://dev-scg-my-benefit-api.moveplus.dynu.net",
    BACKOFFICE_ENDPOINT:
      "https://dev-scg-my-benefit-backoffice.moveplus.dynu.net",
    APP_ENDPOINT: "https://dev-scg-my-benefit-app.moveplus.dynu.net",
    MARKETPLACE_ENDPOINT: "https://marketplace-api-flexben-uat.scg.com",
  },
  staging4: {
    HOST: "https://dev-scg1-my-benefit-backoffice.moveplus.dynu.net",
    API_ENDPOINT:
      "https://dev-scg1-my-benefit-backoffice.moveplus.dynu.net/graphql",
    API: "https://dev-scg-my-benefit-api.moveplus.dynu.net",
    BACKOFFICE_ENDPOINT:
      "https://dev-scg1-my-benefit-backoffice.moveplus.dynu.net",
    APP_ENDPOINT: "https://dev-scg1-my-benefit-app.moveplus.dynu.net",
    MARKETPLACE_ENDPOINT: "https://marketplace-api-flexben-uat.scg.com",
  },
  development: {
    HOST: "http://localhost:22902",
    API_ENDPOINT: "http://localhost:22901/graphql",
    API: "http://localhost:22901",
    BACKOFFICE_ENDPOINT: "http://localhost:22902",
    APP_ENDPOINT: "http://localhost:22903",
    MARKETPLACE_ENDPOINT: "https://marketplace-api-flexben-uat.scg.com",
  },
};

export const getEnvByHost = () =>
  isClient ? findKey(config, { HOST: window.location.origin }) : null;

export const nodeEnv = getEnvByHost() || "development";

console.log("HOST : ", getEnvByHost());

const appEnvConfig = {
  production: {
    HOST: "https://mybenefit-app-flexben.scg.com",
    API_ENDPOINT: "https://mybenefit-app-flexben.scg.com/graphql",
    API: "https://mybenefit-api-flexben.scg.com",
  },
  uat: {
    HOST: "https://mybenefit-app-flexben-uat.scg.com",
    API_ENDPOINT: "https://mybenefit-app-flexben-uat.scg.com/graphql",
    API: "https://mybenefit-api-flexben-uat.scg.com",
  },
  staging: {
    HOST: "https://dev-scg2-flex-app.moveplus.dynu.net",
    API_ENDPOINT: "https://dev-scg2-flex-app.moveplus.dynu.net/graphql",
    API: "https://dev-scg2-flex-api.moveplus.dynu.net",
  },
  staging2: {
    HOST: "http://localhost:9902",
    API_ENDPOINT: "http://localhost:9901/graphql",
    API: "http://localhost:9901",
  },
  staging3: {
    HOST: "https://dev-scg-my-benefit-app.moveplus.dynu.net",
    API_ENDPOINT: "https://dev-scg-my-benefit-app.moveplus.dynu.net/graphql",
    API: "https://dev-scg-my-benefit-api.moveplus.dynu.net",
  },
  staging4: {
    HOST: "https://dev-scg1-my-benefit-app.moveplus.dynu.net",
    API_ENDPOINT: "https://dev-scg1-my-benefit-app.moveplus.dynu.net/graphql",
    API: "https://dev-scg1-my-benefit-api.moveplus.dynu.net",
  },
  development: {
    HOST: "http://localhost:22903",
    API_ENDPOINT: "http://localhost:22901/graphql",
    API: "http://localhost:22901",
  },
};

const MOCK_MSAL_CONFIG = {
  production: {
    REACT_APP_MSAL_CLIENT_ID: "aa3d3245-7975-475f-a0d2-f4728d3f9294",
    REACT_APP_MSAL_AUTHORITY: "https://login.microsoftonline.com/common",
    REACT_APP_MSAL_REDIRECT_URI: "https://mybenefit-backoffice-flexben.scg.com",
    REACT_APP_MSAL_LOGOUT_REDIRECT_URI:
      "https://mybenefit-backoffice-flexben.scg.com",
  },
  uat: {
    REACT_APP_MSAL_CLIENT_ID: "aa3d3245-7975-475f-a0d2-f4728d3f9294",
    REACT_APP_MSAL_AUTHORITY: "https://login.microsoftonline.com/common",
    REACT_APP_MSAL_REDIRECT_URI:
      "https://mybenefit-backoffice-flexben-uat.scg.com",
    REACT_APP_MSAL_LOGOUT_REDIRECT_URI:
      "https://mybenefit-backoffice-flexben-uat.scg.com",
  },
  staging: {
    REACT_APP_MSAL_CLIENT_ID: "96283f69-2148-4bf1-a97a-4478c83a8e41",
    REACT_APP_MSAL_AUTHORITY: "https://login.microsoftonline.com/common",
    REACT_APP_MSAL_REDIRECT_URI:
      "https://dev-scg2-flex-backoffice.moveplus.dynu.net",
    REACT_APP_MSAL_LOGOUT_REDIRECT_URI:
      "https://dev-scg2-flex-backoffice.moveplus.dynu.net",
  },
  staging2: {
    REACT_APP_MSAL_CLIENT_ID: "96283f69-2148-4bf1-a97a-4478c83a8e41",
    REACT_APP_MSAL_AUTHORITY: "https://login.microsoftonline.com/common",
    REACT_APP_MSAL_REDIRECT_URI: "http://localhost:9902",
    REACT_APP_MSAL_LOGOUT_REDIRECT_URI: "http://localhost:9902",
  },
  staging3: {
    REACT_APP_MSAL_CLIENT_ID: "96283f69-2148-4bf1-a97a-4478c83a8e41",
    REACT_APP_MSAL_AUTHORITY: "https://login.microsoftonline.com/common",
    REACT_APP_MSAL_REDIRECT_URI:
      "https://dev-scg-my-benefit-backoffice.moveplus.dynu.net",
    REACT_APP_MSAL_LOGOUT_REDIRECT_URI:
      "https://dev-scg-my-benefit-backoffice.moveplus.dynu.net",
  },
  staging4: {
    REACT_APP_MSAL_CLIENT_ID: "96283f69-2148-4bf1-a97a-4478c83a8e41",
    REACT_APP_MSAL_AUTHORITY: "https://login.microsoftonline.com/common",
    REACT_APP_MSAL_REDIRECT_URI:
      "https://dev-scg1-my-benefit-backoffice.moveplus.dynu.net",
    REACT_APP_MSAL_LOGOUT_REDIRECT_URI:
      "https://dev-scg1-my-benefit-backoffice.moveplus.dynu.net",
  },
  development: {
    REACT_APP_MSAL_CLIENT_ID: "96283f69-2148-4bf1-a97a-4478c83a8e41",
    REACT_APP_MSAL_AUTHORITY: "https://login.microsoftonline.com/common",
    REACT_APP_MSAL_REDIRECT_URI: "http://localhost:22902",
    REACT_APP_MSAL_LOGOUT_REDIRECT_URI: "http://localhost:22902",
  },
};

export const appEnv = appEnvConfig[nodeEnv];

const env = {
  ...config[nodeEnv],
  ...MOCK_MSAL_CONFIG[nodeEnv],
};

console.log("ENV : ", env);
export default env;
