import { compose, withAuthorize, withHooks, withTranslation } from "enhancers";
import { formatDate, gql, homePath, paths } from "utils/helper";
import { Box, Table, Typography, Modal, ExcelGenerator } from "components";
import { PageContent } from "layouts";
import { ReactComponent as downloadIcon } from "assets/icon/download-icon.svg";
import { ReportState } from "constants/enums/report-state";
import { PERMISSIONS } from "constants/enums/permissions";

const ReportComponent = (props: any) => (
  <PageContent title={props.title} breadcrumbs={props.breadcrumbs}>
    <Box width="100%" mb={-6}>
      <Typography variant="h4" mb={4}>
        {props.title}
      </Typography>
      <Table
        columns={props.columns}
        rows={props.tableData}
        loading={props.loading}
        autoHeight
        includeToolbar
      ></Table>
    </Box>
  </PageContent>
);

export const API = {
  FETCH_REPORTS: gql`
    query FETCH_REPORTS {
      reports {
        id
        year
        createdAt
        insuranceCompanyName
        incomingCustomerAmount
        outgoingCustomerAmount
        state
      }
    }
  `,
  EDIT_REPORT_STATE: gql`
    mutation EDIT_REPORT_STATE($id: String!, $newState: String!) {
      editReportState(input: { id: $id, newState: $newState }) {
        report {
          id
          state
        }
      }
    }
  `,
  FETCH_REPORT_DATA: gql`
    query FETCH_REPORT_DATA($id: String!) {
      reportData(id: $id) {
        employeesNew {
          id
          costCenter
          code
          prefixNameTh
          prefixNameEn
          firstNameTh
          firstNameEn
          lastNameTh
          lastNameEn
          maritalStatus
          birthDateInReport
          insuranceEffectiveDateInReport
          citizenId
          gender
          bankName
          bankAccount
          phoneNumber
          email
          no
          insuranceCompanyName
          subCompanyName
          selectInsurance
          stateText
          insuranceId
          departmentTh
        }
        employeesTerminate {
          id
          costCenter
          code
          prefixNameTh
          prefixNameEn
          firstNameTh
          firstNameEn
          lastNameTh
          lastNameEn
          maritalStatus
          birthDateInReport
          insuranceEffectiveDateInReport
          citizenId
          gender
          bankName
          bankAccount
          phoneNumber
          email
          no
          insuranceCompanyName
          subCompanyName
          selectInsurance
          stateText
          insuranceId
          departmentTh
        }
        plans
        insuranceTypes {
          id
          symbol
          nameTh
          nameEn
        }
        enrollmentYear
        insuranceCompanyName
      }
    }
  `,
};

const enhancer = compose(
  withAuthorize(),
  withTranslation({
    prefix: "pages.main.reports.index",
  }),
  withHooks((props: any, hooks: any) => {
    const {
      useQuery,
      useMemo,
      useMutation,
      useEffect,
      useCallback,
      useLazyQuery,
    } = hooks;
    const { t, hasPermission } = props;
    const { loading, data, error, refetch } = useQuery(API.FETCH_REPORTS, {
      fetchPolicy: "network-only",
    });
    const [editReportState] = useMutation(API.EDIT_REPORT_STATE);
    const [fetchEmployeesForReport] = useLazyQuery(API.FETCH_REPORT_DATA, {
      onCompleted: (data: any) => {
        exportReport(data.reportData);
      },
      fetchPolicy: "network-only",
    });

    const hasEditPermission = useMemo(
      () => hasPermission([PERMISSIONS.REPORT_MANAGEMENT_EDIT]),
      [hasPermission]
    );

    const title = t(".reportMenu");
    const breadcrumbs = useMemo(() => {
      return [
        { path: homePath(), label: t(".home") },
        { path: null, label: title },
      ];
    }, [title, t]);
    const columns = useMemo(
      () => [
        { width: 100, field: "year", headerName: t(".columns.year") || "" },
        {
          width: 200,
          field: "createReportDate",
          headerName: t(".columns.createReportDate") || "",
          type: "date",
        },
        {
          width: 120,
          field: "incomingCustomerAmount",
          headerName: t(".columns.incomingCustomerAmount") || "",
        },
        {
          width: 120,
          field: "outgoingCustomerAmount",
          headerName: t(".columns.outgoingCustomerAmount") || "",
        },
        {
          width: 120,
          field: "sendStatus",
          headerName: t(".columns.sendStatus") || "",
          type: "checkBox",
        },
        {
          width: 40,
          headerName: " ",
          filterable: false,
          sortable: false,
          field: "actions",
          type: "actions",
        },
      ],
      [t]
    );

    const changeStatusSent = useCallback(
      async (params: any) => {
        const { id, sendStatus } = params.row;
        console.log(id, sendStatus);
        Modal.open({
          title: t(".modal.title"),
          children: (
            <Box flex={1} mb={5} display="flex" flexDirection="row">
              <Typography variant="body1" color="#767676">
                {t(".modal.description")}
              </Typography>
              <Box ml={1}>
                <Typography variant="body1" color="black" fontWeight={600}>
                  {sendStatus.statusSent
                    ? t(".modal.statusNotSend")
                    : t(".modal.statusSend")}
                </Typography>
              </Box>
            </Box>
          ),
          cancelButtonLabel: t(".modal.cancelButtonLabel"),
          okButtonLabel: t(".modal.okButtonLabel"),
          onOk: async ({ close }: any) => {
            await editReportState({
              variables: {
                id: id,
                newState: sendStatus.statusSent
                  ? ReportState.unsent
                  : ReportState.sent,
              },
            });
            await refetch();
            close();
          },
        });
      },
      [editReportState, refetch, t]
    );

    const exportReport = useCallback((data: any) => {
      const {
        employeesNew,
        employeesTerminate,
        plans,
        insuranceTypes,
        enrollmentYear,
        insuranceCompanyName,
      } = data;
      const columns2 = [
        {
          field: "title",
          title: "แผนประกัน",
        },
      ];
      for (const insuranceType of insuranceTypes) {
        columns2.push({
          field: insuranceType.symbol,
          title: insuranceType.symbol,
        });
      }
      const columns = [
        {
          field: "departmentTh",
          title: "บริษัทพนักงาน",
        },
        {
          field: "no",
          title: "ลำดับที่",
        },
        {
          field: "code",
          title: "รหัสพนักงาน",
        },
        {
          field: "costCenter",
          title: "cost center",
        },

        {
          field: "prefixNameTh",
          title: "คำนำหน้า",
        },
        {
          field: "firstNameTh",
          title: "ชื่อ",
        },
        {
          field: "lastNameTh",
          title: "สกุล",
        },
        {
          field: "maritalStatus",
          title: "สถานะ",
        },
        {
          field: "stateText",
          title: "เข้า (New) ออก (Ter) เปลี่ยน (Chg)",
        },
        {
          field: "birthDateInReport",
          title: "วัน เดือน ปี เกิด",
        },
        {
          field: "insuranceEffectiveDateInReport",
          title: "วันที่มีผลบังคับ",
        },
        {
          field: "selectInsurance",
          title: "แผนประกัน",
        },
        {
          field: "citizenId",
          title: "เลขประจำตัวประชาชน",
        },
        {
          field: "gender",
          title: "เพศ",
        },
        {
          field: "bankName",
          title: "ธนาคาร",
        },
        {
          field: "bankAccount",
          title: "เลขที่บัญชี",
        },
        {
          field: "phoneNumber",
          title: "เบอร์โทรศัพท์",
        },
        {
          field: "email",
          title: "อีเมล",
        },
        {
          field: "subCompanyName",
          title: "บริษัทย่อย",
        },
        {
          field: "insuranceId",
          title: "เลขที่กรมธรรม์",
        },
      ];
      ExcelGenerator.generate({
        fileName: `insurance_report_${enrollmentYear}_${insuranceCompanyName}_${formatDate(
          new Date(),
          "yyyyMMddhhmmss"
        )}`,
        configs: [
          {
            sheetName: "เข้า (New)",
            columns: columns,
            data: employeesNew,
          },
          {
            sheetName: "ออก (Ter)",
            columns: columns,
            data: employeesTerminate,
          },
          {
            sheetName: "แผนประกัน",
            columns: columns2,
            data: plans,
          },
        ],
      });
    }, []);

    const downloadFile = useCallback(
      async (params: any) => {
        //TODO: download file function
        const { id } = params.row;
        await fetchEmployeesForReport({ variables: { id } });
      },
      [fetchEmployeesForReport]
    );

    const tableData = useMemo(() => {
      if (loading || error) {
        return [];
      }
      return data.reports.map((report: any) => {
        const {
          id,
          year,
          createdAt,
          insuranceCompanyName,
          incomingCustomerAmount,
          outgoingCustomerAmount,
          state,
        } = report;
        return {
          id: id,
          year: year,
          createReportDate: createdAt,
          insuranceCompanyName: insuranceCompanyName,
          outgoingCustomerAmount:
            outgoingCustomerAmount === 0
              ? `${outgoingCustomerAmount}`
              : outgoingCustomerAmount,
          incomingCustomerAmount:
            incomingCustomerAmount === 0
              ? `${incomingCustomerAmount}`
              : incomingCustomerAmount,
          sendStatus: {
            statusSent: state === ReportState.sent,
            onClick: changeStatusSent,
            disabled: !hasEditPermission,
          },
          actions: !hasEditPermission
            ? []
            : [
                {
                  Icon: downloadIcon,
                  onClick: downloadFile,
                },
              ],
        };
      });
    }, [
      loading,
      error,
      data,
      changeStatusSent,
      downloadFile,
      hasEditPermission,
    ]);

    return {
      loading,
      title,
      breadcrumbs,
      columns,
      tableData,
    };
  })
);

export const ReportPage = enhancer(ReportComponent);
