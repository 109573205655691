import React from "react";
import { compose, withStores, withHooks, withTranslation } from "enhancers";
import { Box, Avatar, Modal, ClickAwayListener } from "components";
import { gql } from "utils/helper";

import { ReactComponent as ArrowUpIcon } from "assets/icon/keyboard_arrow_up.svg";
import { ReactComponent as ArrowDownIcon } from "assets/icon/keyboard_arrow_down.svg";
import { ReactComponent as LogoutIcon } from "assets/icon/logout_icon.svg";
import { ReactComponent as ProfileIcon } from "assets/icon/profile_icon.svg";
import { MenuItem } from "@material-ui/core";
import Typography from "./Typography";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import appStore from "stores/appStore";
import { AppColor } from "theme/app-color";
import { useMsal } from "@azure/msal-react";

const CustomMenuItem = styled(MenuItem)`
  height: 48px;
`;

const MenuLabel = styled(Typography)`
  margin-left: 18px;
`;

const PopMenu = ({
  Icon,
  SidebarFooterText,
  SidebarFooterSubText,
  ...props
}: any) => (
  <Box
    display="flex"
    width="100%"
    alignItems="center"
    onClick={props.toggle}
    style={{ cursor: "pointer" }}
    position="relative"
  >
    {props.expanded && (
      <ClickAwayListener onClickAway={props.close}>
        <Box
          style={{ backgroundColor: "white" }}
          position="absolute"
          width={226}
          borderRadius={8}
          bottom={68}
          right={0}
          py={4}
        >
          {/* <CustomMenuItem onClick={props.gotoProfilePage}>
            <ProfileIcon />
            <MenuLabel variant="Subtitle/14">{props.t(".profile")}</MenuLabel>
          </CustomMenuItem> */}
          <CustomMenuItem onClick={props.logout}>
            <LogoutIcon />
            <MenuLabel variant="Subtitle/14">{props.t(".logout")}</MenuLabel>
          </CustomMenuItem>
        </Box>
      </ClickAwayListener>
    )}
    <Box>
      <Avatar
        style={{
          backgroundColor: "#376FD0",
          fontSize: 16,
          fontWeight: "bold",
        }}
        src={props.avatarUrl}
      >
        {props.t(".avatar")}
      </Avatar>
    </Box>
    <Box
      display="flex"
      flexDirection="column"
      flex={1}
      ml={4}
      overflow="hidden"
    >
      <SidebarFooterText variant="body2" noWrap>
        {props.firstName} {props.lastName}
      </SidebarFooterText>
      <SidebarFooterSubText variant="caption" noWrap>
        {props.code}
      </SidebarFooterSubText>
    </Box>
    <Box ml={2}>
      <Box
        width={24}
        height={24}
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        <Icon />
      </Box>
    </Box>
  </Box>
);

export const API = {
  FETCH_CURRENT_USER: gql`
    query FETCH_CURRENT_USER {
      getCurrentBackofficeUser {
        ownerType
        admin {
          firstName
          lastName
          code
        }
      }
    }
  `,
  SIGN_OUT: gql`
    mutation SIGN_OUT {
      backofficeSignOut {
        currentUser {
          email
        }
      }
    }
  `,
};

const enhancer = compose(
  withStores((stores: any) => ({
    currentUser: stores.appStore.currentUser,
  })),
  withTranslation({ prefix: "components.common.PopMenu" }),
  withHooks((props: any, hooks: any) => {
    const { useMemo } = hooks;
    const { SidebarFooterText, SidebarFooterSubText, t } = props;
    const { instance } = useMsal();

    const { useState, useCallback, useQuery, useMutation } = hooks;
    const [expanded, setExpanded] = useState(false);

    const { loading, data, error } = useQuery(API.FETCH_CURRENT_USER);
    const [backofficeSignOut] = useMutation(API.SIGN_OUT);

    const currentUser = useMemo(() => {
      if (loading || error) {
        return null;
      }
      return data.getCurrentBackofficeUser;
    }, [loading, data, error]);

    const { firstName, lastName, code } = useMemo(() => {
      return currentUser?.admin ?? {};
    }, [currentUser]);

    const toggle = useCallback(() => {
      setExpanded(!expanded);
    }, [expanded, setExpanded]);

    const close = useCallback(() => {
      setExpanded(false);
    }, [setExpanded]);

    const Icon = expanded ? ArrowUpIcon : ArrowDownIcon;

    const gotoProfilePage = useCallback(() => {
      // paths.profilePath().push();
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data]);

    const gotoSettingPage = useCallback(() => {
      // paths.settingPath().push();
    }, []);

    const logout = useCallback(() => {
      const confirmTxt = "“" + t(".confirmLogout") + "”";
      Modal.open({
        title: t(".logout"),
        children: (
          <>
            <div style={{ display: "flex", flexDirection: "row" }}>
              <Typography variant="body1">
                {t(".logoutInfo1")} &nbsp;
              </Typography>
              <Typography variant="body2" color={AppColor["Text/Line"]}>
                {confirmTxt}
              </Typography>
            </div>
            <Typography variant="body1">{t(".logoutInfo2")}</Typography>
          </>
        ),
        cancelButtonLabel: t(".cancel"),
        okButtonLabel: t(".confirmLogout"),
        okButtonColor: AppColor["Primary/Primary"],
        onOk: async ({ close }: any) => {
          instance.setActiveAccount(null);
          appStore.logout();
        },
      });
    }, [instance, t]);

    const currentUserIsSuperAdmin =
      data?.getCurrentBackofficeUser?.admin?.isSuperAdmin;

    return {
      expanded,
      Icon,
      toggle,
      close,
      gotoProfilePage,
      gotoSettingPage,
      logout,
      firstName,
      lastName,
      code,
      SidebarFooterText,
      SidebarFooterSubText,
      currentUserIsSuperAdmin,
    };
  })
);

export default enhancer(PopMenu);
