import { ReactComponent as FamilyInsuranceIcon } from "assets/icon/family_insurance.svg";
import { ReactComponent as PencilBlueIcon } from "assets/icon/pencil_blue_icon.svg";
import { ReactComponent as UserIcon } from "assets/icon/user.svg";
import { Alert, Box, Button, Modal, Paper, Typography } from "components";
import { MaterialIcon } from "components/common/MaterialIcon";
import { EnumInsuranceFor } from "constants/enums/insurance-for";
import { EnumMasterInsuranceState } from "constants/enums/master-insurance-state";
import { compose, withHooks, withTranslation } from "enhancers";
import styled from "styled-components";
import { AppColor } from "theme/app-color";
import {
  formatDate,
  gql,
  notifySuccess,
  paths,
  publishedAlert,
} from "utils/helper";
import { insuranceStepPath } from "../insuranceRoutes";
import InsuranceTableComponent from "./InsuranceTableComponent";

const mapIcon = {
  employee: <UserIcon />,
  family: <FamilyInsuranceIcon />,
};

const EditButton = styled(Button)``;

const HeadIcon = styled(MaterialIcon)`
  font-size: 24px;
`;

const InsurancePackagePreviewPage = (props: any) => (
  <>
    <Paper p={4} mb={4} style={{ borderRadius: "4px" }}>
      <Box width="100%">
        <Typography variant="h4" mb={4}>
          {props.t(".title")}
        </Typography>
        <Alert severity="warning">
          <Typography variant="body1" color={AppColor["Text/Primary"]}>
            {props.t(".warning")}
          </Typography>
        </Alert>
      </Box>
    </Paper>

    <Paper p={4} mb={4} style={{ borderRadius: "4px" }}>
      <Box width="100%">
        <Box display="flex" justifyContent="space-between">
          <Box display="flex" flexDirection="row" alignItems="center" mb={2}>
            <Box display="flex" mr={2}>
              <HeadIcon
                name="WatchLaterOutlined"
                htmlColor={AppColor["Secondary/Hover"]}
              />
            </Box>
            <Typography variant="h6" color={AppColor["Secondary/Hover"]}>
              {props.t(".subtitle1")}
            </Typography>
          </Box>
          {props.canEdit && (
            <EditButton onClick={props.editInsurance} variant="outlined">
              <PencilBlueIcon style={{ marginRight: "10px" }} />
              {props.t(".edit")}
            </EditButton>
          )}
        </Box>

        <Box display="flex">
          <Box display="flex" flexDirection="column" flex={1}>
            <Typography variant="h6" mb={2}>
              {props.t(".startDate")}
            </Typography>

            <Typography variant="h6" mb={2}>
              {props.t(".endDate")}
            </Typography>
          </Box>

          <Box display="flex" flexDirection="column" flex={1}>
            <Typography variant="body1" mb={2}>
              {props.protectionStartDate || "-"}
            </Typography>

            <Typography variant="body1" mb={2}>
              {props.protectionEndDate || "-"}
            </Typography>
          </Box>
        </Box>
      </Box>
    </Paper>

    <Paper p={4} mb={4} style={{ borderRadius: "4px" }}>
      <Box width="100%">
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Box display="flex" alignItems="center">
            <Box
              display="flex"
              alignItems="center"
              justifyContent="center"
              width="24px"
              height="24px"
            >
              <UserIcon />
            </Box>
            <Typography
              variant="body2"
              ml="8px"
              color={AppColor["Secondary/Hover"]}
            >
              {props.t(`.insuranceFor.employee`)}
            </Typography>
          </Box>
          {props.canEdit && (
            <EditButton onClick={props.editPackage} variant="outlined">
              <PencilBlueIcon color={AppColor["Primary/Primary"]} />
              {props.t(".edit")}
            </EditButton>
          )}
        </Box>

        <Box mt="16px">
          <InsuranceTableComponent
            masterInsurances={props.masterInsurancePlans}
          />
        </Box>
      </Box>
    </Paper>

    <Paper p={4} style={{ borderRadius: "4px" }}>
      <Box width="100%">
        <Alert severity="warning">
          <Typography variant="body1">{props.t(".warning")}</Typography>
        </Alert>
        {props.canEdit && (
          <Button mt={6} onClick={props.handleClickSaveAndNext}>
            {props.t(props.switchPublishState.submitButton)}
          </Button>
        )}
      </Box>
    </Paper>
  </>
);

export const API = {
  FETCH_INSURANCE_DETAIL: gql`
    query FETCH_INSURANCE_DETAIL($id: String!) {
      masterInsuranceDetail(id: $id) {
        id
        year
        state
        subState
        companyName
        protectionStartDate
        protectionEndDate
        insurances {
          id
          nameTh
          nameEn
          premium
          remarkTh
          remarkEn
          description
          insuranceTypeId
          isFamily
          coverageLevel
        }
        insurancePackages {
          id
          nameTh
          nameEn
          remarkTh
          remarkEn
          insurancesInsurancePackages {
            id
            insurancePlanId
            packageId
          }
          isFamily
          coverageLevel
          description
          premium
        }
      }
    }
  `,
  PUBLISH_INSURANCE: gql`
    mutation PUBLISH_INSURANCE($id: String!) {
      publishInsurance(id: $id) {
        id
        year
        state
      }
    }
  `,
};

const enhancer = compose(
  withTranslation({ prefix: "pages.main.insurance.InsurancePreview" }),
  withHooks((props: any, hooks: any) => {
    const {
      useMemo,
      useCallback,
      useMutation,
      useParams,
      useQuery,
      useDataTranslation,
    } = hooks;
    const { t } = props;

    const { id: insuranceId } = useParams();

    const { data: masterInsurancePlansQry } = useQuery(
      API.FETCH_INSURANCE_DETAIL,
      {
        variables: { id: insuranceId },
        fetchPolicy: "network-only",
      }
    );

    const masterInsurancePlans = useDataTranslation(
      masterInsurancePlansQry?.masterInsuranceDetail
    );

    const [publishInsurance] = useMutation(API.PUBLISH_INSURANCE, {
      skipSetError: true,
      onError: () => {
        publishedAlert();
      },
      onCompleted: () => {
        Modal.close();
        paths.insurancePath().push();
        notifySuccess(t(".success"));
      },
    });

    const editInsurance = useCallback(() => {
      insuranceStepPath.insuranceDetail(insuranceId).push();
    }, [insuranceId]);

    const editPackage = useCallback(() => {
      insuranceStepPath.insurancePackage(insuranceId).push();
    }, [insuranceId]);

    const canEdit = useMemo(
      () =>
        EnumMasterInsuranceState.published !== masterInsurancePlans?.state
          ? true
          : false,
      [masterInsurancePlans]
    );

    const switchPublishState = useMemo(() => {
      if (EnumMasterInsuranceState.draft === masterInsurancePlans?.state) {
        return {
          submitButton: ".publish",
          modalTitle: ".modalTitle",
          modalWarning: ".warning",
        };
      } else {
        return {
          submitButton: ".rePublish",
          modalTitle: ".rePublishModalTitleWarning",
          modalWarning: ".rePublishModalWarning",
        };
      }
    }, [masterInsurancePlans]);

    const handleClickSaveAndNext = useCallback(() => {
      Modal.open({
        title: t(switchPublishState.modalTitle),
        children: (
          <>
            <div style={{ display: "flex", flexDirection: "row" }}>
              <Typography variant="body1" color={AppColor["Text/Dark Grey"]}>
                {t(switchPublishState.modalWarning)}
              </Typography>
            </div>
          </>
        ),
        cancelButtonLabel: t(".cancel"),
        okButtonLabel: t(switchPublishState.submitButton),
        onOk: async ({ close }: any) => {
          await publishInsurance({ variables: { id: insuranceId } });
        },
        okButtonColor: AppColor["Other/Danger"],
        okButtonVariant: "outlined",
      });
    }, [insuranceId, publishInsurance, t, switchPublishState]);

    const protectionStartDateFormatted = useMemo(
      () => formatDate(masterInsurancePlans?.protectionStartDate, "dd/MM/yyy"),
      [masterInsurancePlans]
    );

    const protectionEndDateFormatted = useMemo(
      () => formatDate(masterInsurancePlans?.protectionEndDate, "dd/MM/yyy"),
      [masterInsurancePlans]
    );

    return {
      editInsurance,
      editPackage,
      handleClickSaveAndNext,
      switchPublishState,
      canEdit,
      protectionStartDate: protectionStartDateFormatted,
      protectionEndDate: protectionEndDateFormatted,
      masterInsurancePlans,
    };
  })
);

export default enhancer(InsurancePackagePreviewPage);
